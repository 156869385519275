/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import {
  FormatLocaleDefinition,
  format as d3Format,
  formatLocale,
} from 'd3-format';
import NumberFormatter from '../NumberFormatter';
import NumberFormats from '../NumberFormats';

const siFormat = `,.3~s`;
const float2PointFormat = `,.2~f`;
const float4PointFormat = `,.4~f`;

type NumFormatter = ReturnType<typeof d3Format>;

const FORMATTERS_CACHE = new Map<
  FormatLocaleDefinition | undefined,
  Map<string, NumFormatter>
>();

const getFormatter = ({
  format,
  locale,
}: {
  format: string;
  locale?: FormatLocaleDefinition;
}) => {
  const actualLocale: FormatLocaleDefinition | undefined =
    // @ts-ignore
    locale ?? window.supersetLangNumberFormat;
  let formatter: NumFormatter | undefined;

  let cache = FORMATTERS_CACHE.get(actualLocale);
  if (!cache) {
    cache = new Map();
    FORMATTERS_CACHE.set(actualLocale, cache);
  }

  formatter = cache.get(format);
  if (!formatter) {
    formatter = actualLocale
      ? formatLocale(actualLocale).format(format)
      : d3Format(format);
    cache.set(format, formatter);
  }

  return formatter;
};

function formatValue(value: number) {
  if (value === 0) {
    return '0';
  }
  const absoluteValue = Math.abs(value);
  if (absoluteValue >= 1000) {
    // Normal human being are more familiar
    // with billion (B) that giga (G)
    return getFormatter({ format: siFormat })(value).replace('G', 'B');
  }
  if (absoluteValue >= 1) {
    return getFormatter({ format: float2PointFormat })(value);
  }
  if (absoluteValue >= 0.001) {
    return getFormatter({ format: float4PointFormat })(value);
  }
  if (absoluteValue > 0.000001) {
    return `${getFormatter({ format: siFormat })(value * 1000000)}µ`;
  }
  return getFormatter({ format: siFormat })(value);
}

const processSiPrefixes = ({
  origValue,
  mapping,
}: {
  origValue: string;
  mapping?: { [key: string]: string };
}) => {
  // @ts-ignore
  const actualMapping = mapping ?? window.supersetLangSiPrefixes;
  if (actualMapping) {
    return Object.keys(actualMapping).reduce(
      (acc, key) => acc.replaceAll(key, actualMapping[key]),
      origValue,
    );
  }
  return origValue;
};

export default function createSmartNumberFormatter(
  config: {
    description?: string;
    signed?: boolean;
    id?: string;
    label?: string;
  } = {},
) {
  const { description, signed = false, id, label } = config;
  const getSign = signed ? (value: number) => (value > 0 ? '+' : '') : () => '';

  return new NumberFormatter({
    description,
    formatFunc: value =>
      `${getSign(value)}${processSiPrefixes({
        origValue: formatValue(value),
      })}`,
    id:
      id || signed
        ? NumberFormats.SMART_NUMBER_SIGNED
        : NumberFormats.SMART_NUMBER,
    label: label ?? 'Adaptive formatter',
  });
}
