export type DateTimeFormatConvertionMapping = { [key: string]: string };

const REGEXP_KEY_PREFIX = 'regexp:';

const isRegexpKey = (key: string) => key.startsWith(REGEXP_KEY_PREFIX);
const getRegexpStringFromKey = (key: string) => {
  let regExp = key.substring(REGEXP_KEY_PREFIX.length);
  if (!regExp.substring(regExp.lastIndexOf('/') + 1).includes('g')) {
    regExp += 'g';
  }
  return regExp;
};

// eslint-disable-next-line no-eval
const getRegexpFromKey = (key: string) => eval(getRegexpStringFromKey(key));
export const toRegexpKey = (key: string) => `${REGEXP_KEY_PREFIX}${key}`;

const CONVERTED_FORMATS_CACHE = new Map<
  DateTimeFormatConvertionMapping,
  Map<string, string>
>();

const getCacheForMapping = (mapping: DateTimeFormatConvertionMapping) => {
  let cacheForMapping = CONVERTED_FORMATS_CACHE.get(mapping);
  if (!cacheForMapping) {
    cacheForMapping = new Map();
    CONVERTED_FORMATS_CACHE.set(mapping, cacheForMapping);
  }
  return cacheForMapping;
};

const getConvertedFormatFromCache = (
  mapping: DateTimeFormatConvertionMapping,
  origFormat: string,
) => getCacheForMapping(mapping).get(origFormat);

const saveConvertedFormatToCache = (
  mapping: DateTimeFormatConvertionMapping,
  origFormat: string,
  convertedFormat: string,
) => {
  getCacheForMapping(mapping).set(origFormat, convertedFormat);
};

const loggedConvertedFormat = (origFormat: string, convertedFormat: string) => {
  if (convertedFormat !== origFormat) {
    console.log(
      'Converted date format from ',
      origFormat,
      ' to ',
      convertedFormat,
    );
  }
  return convertedFormat;
};

export const dateTimeFormatConverter = ({
  origFormat,
  mapping,
}: {
  origFormat: string;
  mapping?: DateTimeFormatConvertionMapping;
}): string => {
  // @ts-ignore
  const actualMapping: DateTimeFormatConvertionMapping | undefined =
    // @ts-ignore
    mapping ?? window.supersetTimeLocaleDefinition?.formatMapping;

  if (!actualMapping) {
    return loggedConvertedFormat(origFormat, origFormat);
  }

  if (actualMapping[origFormat]) {
    return loggedConvertedFormat(origFormat, actualMapping[origFormat]);
  }

  let convertedFormat =
    getConvertedFormatFromCache(actualMapping, origFormat) ?? '';
  if (convertedFormat) {
    return loggedConvertedFormat(origFormat, convertedFormat);
  }

  convertedFormat = Object.keys(actualMapping)
    .sort((a, b) => {
      if (isRegexpKey(a) && isRegexpKey(b)) {
        return (
          getRegexpStringFromKey(b).length - getRegexpStringFromKey(a).length
        );
      }
      if (isRegexpKey(a)) {
        return 1;
      }
      if (isRegexpKey(b)) {
        return -1;
      }
      return b.length - a.length;
    })
    .reduce(
      (acc, key) =>
        acc.replaceAll(
          isRegexpKey(key) ? getRegexpFromKey(key) : key,
          actualMapping[key],
        ),
      origFormat,
    );

  saveConvertedFormatToCache(actualMapping, origFormat, convertedFormat);

  return loggedConvertedFormat(origFormat, convertedFormat);
};
